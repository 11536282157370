.sticky-nav-top {
    position: sticky;
    top: 0;
    z-index: 1020;
}

.margin-left {
    margin-left: 10%;
}

.padding-left {
    margin-left: 10%;
}

.div-gray {
    background-color: #F8F8F8 !important;
}

@media only screen and (max-width: 991px) {
    body {
        max-width: 100vw;
        overflow-x: hidden;
        /* Prevent horizontal scrolling */
    }
}

.center-all-items {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.SpinnerForLoading {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #fff;
    color: #e1dfdf;
    height: 200px;
}