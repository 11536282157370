/* Remove default list styling */
ul {
  list-style-type: none;
  padding: 0;
}

.hfFooter-wrapper {
  margin-top: 30px;
  background-color: rgb(234, 238, 242);
  height: auto;
}

.hfFooter-container {
  padding-top: 30px;
}


ul li {
  margin-bottom: 1rem;
  margin-top: 1rem;
}

h {
  color: #30557d;
  font-weight: 500;
}

.footer-link {
  text-decoration: none;
  color: #30557d;
  font-weight: 500;
}

ul li span {
  margin-right: 0.5rem;
  /* Adjust the value as needed */
}

.hfFooter-container>.row:first-child {
  border-bottom: 1px solid #ccc;
  /* Adjust color and style as needed */
  padding: 20px;
}

.hfFooter-container>.row:nth-child(2) {
  padding: 20px;
  /* Add padding */
}

.custom-3row {
  width: 30%;
  height: auto;
  margin: auto;
}

/* For mobile view */
@media (max-width: 768px) {
  .custom-3row {
    width: 80%;
  }
}



.circle-col {
  display: flex;
  justify-content: center;
  text-align: center;

  margin-bottom: 15px;
}

.circle-col>a>div {
  width: 50px;
  /* Adjust size as needed */
  height: 50px;
  /* Adjust size as needed */
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  /* Makes the div circular */
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(100, 22, 22);
}

.circle-col img {
  max-width: 20px;
  /* Adjust image size as needed */
  max-height: 21px;
  /* Adjust image size as needed */
  /* Make the image circular */
}

.line-row {
  margin-top: 10px;
  /* Adjust the margin as needed */
}


.line-col {
  border-top: 1px solid #ccc;
  /* Add a solid line */
  width: 100%;
  /* Make the line span the entire width */
}

.HfFooter-link-div {
  text-align: center;
  margin-top: 30px;
  padding-bottom: 30px;
  gap: 10px;
}

.hf-footer-link {
  color: #30557d;
  text-decoration: none;
}




.footer-column-div {
  color: #30557d !important
}

.footer-link {
  text-decoration: none;
  color: #30557d !important
}